import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BsFacebook, BsWhatsapp, BsMailbox2, BsHouseDoor } from 'react-icons/bs';
import Comment from './Comment';
import Comments from './Comments';
import './Style.css';

const Detalles = () => {
    const { id } = useParams();
    const [datos, setDatos] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchServiceDetails = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/services/details/${id}`);
                setDatos(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchServiceDetails();
    }, [id]);

    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }
    if (error) {
        return <div className="error">Error: {error}</div>;
    }
    if (!datos) {
        return <div className="no-data">No service details available.</div>;
    }

    const socialMediaLinks = {
        facebook: BsFacebook,
        whatsapp: BsWhatsapp,
        ubicacion: BsHouseDoor,
        email: BsMailbox2,
        address: BsHouseDoor
    };

    return (
        <div className="container text-white">
            <div className="row">
                <div className="col-lg-6">
                    {/* Título */}
                    <h1 className="details-title">{datos.name}</h1>

                    {/* Imágen */}
                    <div className="img-card centered-img">
                        {datos.images?.map((img, index) => {
                            const imgUrl = `${process.env.REACT_APP_SERVER_URL}/${img.replace(/^\/var\/www\//, '')}`;
                            return (
                                <div key={index} className="img-wrapper">
                                    <img className="img"
                                        src={imgUrl}
                                        alt={datos.name}
                                        onClick={() => setSelectedImage(imgUrl)}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    {/* Datos */}
                    <div className="social-media">
                        {Object.entries(socialMediaLinks).map(([key, Icon]) =>
                            datos[key] &&
                            <div className="social-icon" key={key}>
                                <Icon className="icon" />
                                <a href={datos[key]} target="_blank" rel="noopener noreferrer" className="truncate-link">
                                    {datos[key]}
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {/* Descripción y mapa */}
                <div className="col-lg-6">
                    <div className="card-detalle">
                        <div className="card-body">
                            <p className="card-cuerpo">
                                {datos.description}
                            </p>
                        </div>
                    </div>

                    <div className="mapa">
                        <iframe
                            src={datos.mapa}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        ></iframe>
                    </div>
                </div>
            </div>

            {/* Comentarios y nueva calificación */}
            <div className="row mt-5">
                <div className="col-lg-6">
                    <Comments serviceId={datos.id} />
                </div>
                <div className="col-lg-6">
                    <Comment serviceId={datos.id} />
                </div>
            </div>

            {/* Imagen ampliada */}
            {selectedImage && (
                <div className="overlay" onClick={() => setSelectedImage(null)}>
                    <img src={selectedImage} alt="Ampliada" className="expanded-img" />
                </div>
            )}
        </div>
    );
};

export default Detalles;
