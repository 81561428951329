import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditarUsuarios = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/users`, {
                    withCredentials: true
                });
                setUsers(response.data);
            } catch (error) {
                const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
                console.error('Error al obtener los usuarios:', errorMessage);
            }
        };

        fetchUsers();
    }, []);

    const handleUserChange = (event) => {
        const userId = event.target.value;
        setSelectedUser(userId);

        const user = users.find(u => u.id === userId);
        setSelectedRole(user ? user.role : '');
    };

    const handleRoleChange = (event) => {
        const newRole = event.target.value;
        setSelectedRole(newRole);
    };

    const handleUpdateClick = async () => {
        if (!selectedRole) {
            setMessage('Por favor, seleccione un rol antes de actualizar');
            setIsError(true);
            return;
        }

        try {
            await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/auth/users/${selectedUser}`, {
                role: selectedRole
            }, {
                withCredentials: true
            });
            setMessage('Usuario actualizado correctamente');
            setIsError(false);
        } catch (error) {
            const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
            setMessage(errorMessage);
            setIsError(true);
        }
    };

    return (
        <div className="service-form">
            <h2>Editar Usuarios</h2>
            {message && <div className={`alert ${isError ? 'alert-danger' : 'alert-success'}`}>{message}</div>}
            <div className="service-edit__group">
                <label>Usuario:</label>
                <select className='rounded-2 text-white' style={{marginLeft:'5px'}} value={selectedUser} onChange={handleUserChange}>
                    <option value="">Seleccione un usuario</option>
                    {users.map(user => (
                        <option key={user.id} value={user.id}>
                            {user.name_full}
                        </option>
                    ))}
                </select>
            </div>
    
            {selectedUser && (
                <div className="service-edit__group">
                    <label>Rol:</label>
                    <select className='rounded-2 text-white' style={{marginLeft:'5px'}} value={selectedRole} onChange={handleRoleChange}>
                        <option value="">Seleccione un rol</option>
                        <option value="normal">Normal</option>
                        <option value="creador">Creador</option>
                        <option value="admin">Admin</option>
                    </select>
                    <button onClick={handleUpdateClick}>Actualizar</button>
                </div>
            )}
        </div>
    );
};

export default EditarUsuarios;