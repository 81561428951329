import React, { useState, useCallback } from 'react';
import axios from 'axios';

function CrearServicioForm({ onServiceAdded, initialData = {}, mode = "create" }) {
  const [formData, setFormData] = useState({
    type: '', // Add 'type' to initial state
    name: '',
    description: '',
    mapa: '', // Add 'mapa' to initial state
    whatsapp: '',
    facebook: '',
    email: '',
    address: '',
    ...initialData // This will overwrite with initialData if present
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Definir opciones para el campo 'type'
  const serviceTypes = [
    { value: 'Artesanias', label: 'Artesanias' },
    { value: 'Alojamientos', label: 'Alojamientos' },
    { value: 'Almacenes', label: 'Almacenes' },
    { value: 'Campings', label: 'Campings' },
    { value: 'Actividades', label: 'Actividades' },
    { value: 'Restaurantes', label: 'Restaurantes' },
    { value: 'Supermercados', label: 'Supermercados' },
    { value: 'Otros', label: 'Otros' }
  ];

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  }, []);

  const handleFileChange = useCallback((e) => {
    setSelectedFiles(Array.from(e.target.files));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = new FormData();
    for (const key in formData) {
      submissionData.append(key, formData[key]);
    }
    for (const file of selectedFiles) {
      submissionData.append('image', file);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/service/service`, submissionData, {
        withCredentials: true
      });
      onServiceAdded(response.data);
      setSuccessMessage('Servicio guardado con éxito.');
      setErrorMessage(null); 

      // Limpiar todos los campos
      setTimeout(() => {
        setFormData({
          type: '',
          name: '',
          description: '',
          mapa: '',
          whatsapp: '',
          facebook: '',
          email: '',
          address: '',
        });
        setSuccessMessage(null); // Campo limpio
      }, 1000);
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
      setErrorMessage(errorMessage);
      setSuccessMessage(null); 
    }
  };

  
  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <div>
        <label htmlFor="type">Tipo de Servicio:</label>
        <select
          className='text-white rounded-2'
          style={{ marginLeft: '5px' }}
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value="">Seleccione un tipo</option>
          {serviceTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="name">Nombre del Servicio</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div>
        <label className='mt-2' htmlFor="description">Descripción</label>
        <br />
        <textarea id="description" name="description" className='perfil-descripcion text-white' value={formData.description} onChange={handleChange} required></textarea>
      </div>
      <div>
        <label htmlFor="whatsapp">WhatsApp:</label>
        <input type="text" id="whatsapp" name="whatsapp" value={formData.whatsapp} onChange={handleChange} />
      </div>
      <div>
        <label className='mt-2' htmlFor="facebook">Facebook:</label>
        <input type="text" id="facebook" name="facebook" value={formData.facebook} onChange={handleChange} />
      </div>
      <div>
        <label className='mt-2' htmlFor="email">Correo:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
      </div>
      <div>
        <label className='mt-2' htmlFor="address">Dirección:</label>
        <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
      </div>
      <div>
        <p></p>
        <label style={{ marginRight: '5px' }} htmlFor="mapa">Mapa (Link):</label>
        <input className='rounded-2' type="url" id="mapa" name="mapa" value={formData.mapa} onChange={handleChange} />
      </div>
      <div className='mt-3'>
        <label className='perfil-editar-imagen' style={{ marginRight: '5px' }} htmlFor="images">Imágenes:</label>
        <input className='rounded-3' style={{ backgroundColor: 'darkolivegreen' }} type="file" id="images" name="image" onChange={handleFileChange} />
      </div>
      <button type="submit">Crear Servicio</button>
    </form>
  );
}

export default CrearServicioForm;