import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import './Style/Comment.css';

const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9"
};

function Comment({ serviceId }) {
    const [currentValue, setCurrentValue] = useState(0);
    const [hoverValue, setHoverValue] = useState(undefined);
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState("");
    const [ratingError, setRatingError] = useState("");
    const stars = Array(5).fill(0);

    const handleClick = value => {
        setCurrentValue(value);
    };

    const handleMouseOver = newHoverValue => {
        setHoverValue(newHoverValue);
    };

    const handleMouseLeave = () => {
        setHoverValue(undefined);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const submitComment = async () => {
        setCommentError("");
        setRatingError("");

        if (comment.length < 10) {
            setCommentError("El comentario debe tener al menos 10 caracteres.");
            return;
        }

        if (currentValue < 1) {
            setRatingError("Debe seleccionar al menos una estrella.");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/service/comment`, {
                serviceId,
                rating: currentValue,
                comment
            }, {
                withCredentials: true

            });

            if (response.status === 201) {
                console.log('Comentario agregado exitosamente');
            }
        } catch (error) {
            console.error('Error al enviar el comentario:', error);
        }
    };

    return (
        <div className="comment-container">
            <h2> Deja tu Calificación </h2>
            <div className="comment-stars">
                {stars.map((_, index) => (
                    <FaStar
                        key={index}
                        size={24}
                        onClick={() => handleClick(index + 1)}
                        onMouseOver={() => handleMouseOver(index + 1)}
                        onMouseLeave={handleMouseLeave}
                        color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                        className="comment-star"
                    />
                ))}
            </div>
            <textarea
                placeholder="Cuentanos tu experiencia..."
                className="comment-textarea text-white"
                value={comment}
                onChange={handleCommentChange}
            />
            {commentError && <div className="comment-error">{commentError}</div>}
            {ratingError && <div className="rating-error">{ratingError}</div>}
            <button
                onClick={submitComment}
                className="comment-submit-button"
            >
                Enviar
            </button>
        </div>
    );
}



export default Comment;

