import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Map from './components/Map/Map';
import Login from './components/Auth/Login';
import Registro from './components/Auth/Registro';
import Servicios from './components/Servicios/Servicios';
import DetalleServicio from './components/Servicios/Detalles'; // Import the DetalleServicio component

import Quienessomos from './components/Quienessomos/Quienessomos';
import Turismo from './components/Turismo/turismo';
import Perfil from './components/Perfil/Admin';
import Novedades from './components/Novedades/Novedades';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          {/* Rutas de servicios genéricas */}
          <Route path="/servicios/:type" element={<Servicios />} />
          <Route path="/DetalleServicio/:id" element={<DetalleServicio />} />{/* Enable this route */}
          
          <Route path="/turismo" element={<Turismo />} />
          <Route path="/atractivos" element={<Novedades />} />
          <Route path="/Quienessomos" element={<Quienessomos />} />
          <Route path="/mapa" element={<Map />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/perfil" element={<Perfil />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
