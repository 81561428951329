import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Style/Perfil.css';

const UserProfile = () => {
    const [profileImage, setProfileImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState('');
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const token = localStorage.getItem('token');
    const defaultImage = `${serverUrl}/uploads/profiles/default.jpg`;

    const axiosRequest = async (method, url, data = null, headers = {}) => {
        try {
            const response = await axios({
                method,
                url: `${serverUrl}${url}`,
                headers: { ...headers },
                data,
                withCredentials: true // Asegúrate de que las cookies se envíen con la solicitud
            });
            return response;
        } catch (error) {
            console.error(`Error with ${method} request to ${url}:`, error);
            setMessage(error.response?.data.message || error.message);
        }
    };

    useEffect(() => {
        const fetchProfileImage = async () => {
            const response = await axiosRequest('get', '/api/auth/get-profile-image');
            if (response) {
                const imagePath = response.data.profileImagePath;
                const imageUrl = imagePath.startsWith('http') ? imagePath : `${serverUrl}/${imagePath.replace(/\\/g, '/')}`;
                setProfileImage(imageUrl);
            }
        };

        fetchProfileImage();
    }, []);

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setProfileImage(e.target.result);
            };

            reader.readAsDataURL(event.target.files[0]);
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedFile) {
            setMessage('Por favor, selecciona una imagen para subir.');
            return;
        }

        const formData = new FormData();
        formData.append('profileImage', selectedFile);

        const response = await axiosRequest('post', '/api/auth/upload-profile-image', formData, {
            'Content-Type': 'multipart/form-data'
        });

        if (response && response.status === 200) {
            setMessage('Imagen subida con éxito.');
            setProfileImage(`${serverUrl}/${response.data.profileImagePath.replace(/\\/g, '/')}`);
            setSelectedFile(null); // Limpia el archivo seleccionado después de la carga exitosa
            event.target.reset(); // Limpia el formulario, útil para limpiar el input de archivo
        }
    };

    const handleDeleteImage = async () => {
        const response = await axiosRequest('delete', '/api/auth/delete-profile-image');

        if (response && response.status === 200) {
            setMessage('Imagen eliminada con éxito.');
            setProfileImage(defaultImage); // Actualiza la imagen de perfil a la predeterminada
        }
    };

    return (
        <div>
            <div className="message-container">
                {message && <div className="alert alert-info">{message}</div>}
            </div>
            <form onSubmit={handleSubmit} className="user-form" style={{ marginTop: "20px" }}>
                <div className="card-body text-center">
                    <div className="mt-3 mb-4">
                        <img
                            src={profileImage || defaultImage}
                            className="rounded-circle img-fluid profile-image"
                            alt="Perfil de usuario"
                        />
                        <input type="file" onChange={handleImageChange} style={{ marginTop: '30px' }} />
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button
                                    className="boton-center boton"
                                    type="submit"
                                    style={{ marginRight: '10px' }}
                                >
                                    Actualizar Imagen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UserProfile;