import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import './RegisterForm.css';

function RegisterForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: ''
  });

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/register`, formData);

      if (response.status === 200 || response.status === 201) {
        // Almacenar el token en local storage y redirigir al usuario según sea necesario
        setMessage(response.data.message);
        setMessageType('success');
        // Redirigir al usuario a la página de inicio de sesión
        window.location = '/login';
      } else {
        // Manejar caso cuando la respuesta no es lo que se espera
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      // Manejar error (mostrar mensaje de error al usuario, etc.)
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
        setMessageType('error');
      } else {
        // Manejar caso cuando el error no es lo que se espera
        console.error('Unexpected error:', error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="register-form mt-2">
      {message && <div className={`message ${messageType}`}>{message}</div>}
      <div className="register-form__icon-container">
        <FontAwesomeIcon icon={faUser} className="register-form__icon" />
      </div>
      <div className="register-form__group">
        <label htmlFor="fullName" className="register-form__label">Nombre y Apellido</label>
        <input type="text" className="register-form__input" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} />
      </div>
      <div className="register-form__group">
        <label htmlFor="email" className="register-form__label">Correo Electrónico</label>
        <input type="email" className="register-form__input" id="email" name="email" value={formData.email} onChange={handleChange} />
      </div>
      <div className="register-form__group">
        <label htmlFor="password" className="register-form__label">Contraseña</label>
        <input type="password" className="register-form__input" id="password" name="password" value={formData.password} onChange={handleChange} />
      </div>
      <button type="submit" className="register-form__button">Registrarse</button>
    </form>
  );
}

export default RegisterForm;
