import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Style/EditarServicio.css"

function EditarServicio() {
  const [userServices, setUserServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedService, setEditedService] = useState({});
  const [serviceImages, setServiceImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [userRole, setUserRole] = useState('');


  // Define the options for the 'type' dropdown
  const serviceTypes = [
    { value: 'Artesanias', label: 'Artesanias' },
    { value: 'Alojamientos', label: 'Alojamientos' },
    { value: 'Almacenes', label: 'Almacenes' },
    { value: 'Campings', label: 'Campings' },
    { value: 'Actividades', label: 'Actividades' },
    { value: 'Restaurantes', label: 'Restaurantes' },
    { value: 'Supermercados', label: 'Supermercados' },
    { value: 'Otros', label: 'Otros' }
  ];

  const fetchServiceImages = async (serviceId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/service/${serviceId}/images`, {
        withCredentials: true
      });
      setServiceImages(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error al obtener las imágenes del servicio:', error);
    }
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/user/role`, {
          withCredentials: true
        });
        setUserRole(response.data.role);
        console.log(response.data.role);
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
      }
    };

    fetchUserRole();
  }, []);

  useEffect(() => {
    const fetchUserServices = async () => {
      try {
        let response;
        if (userRole === 'admin') {
          console.log('El usuario es administrador');
          // Si el usuario es administrador, obtiene todos los servicios
          response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/services`, {
            withCredentials: true
          });
        } else {
          // Si el usuario no es administrador, solo obtiene sus propios servicios
          response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/get_services`, {
            withCredentials: true
          });
        }
        setUserServices(response.data);
      } catch (error) {
        const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
        console.error(errorMessage);
      }
    };

    if (selectedService) {
      fetchServiceImages(selectedService.id);
    } else {
      setServiceImages([]);
    }

    fetchUserServices();
  }
    , [selectedService, userRole]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleEditClick = (service) => {
    setSelectedService(service);
    setIsEditing(true);
    setEditedService(service);
  };

  const handleEditComplete = () => {
    setSelectedService(null);
    setIsEditing(false);
    setEditedService({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedService({ ...editedService, [name]: value });
  };

  // Add the handlers for the new fields
  const handleTypeChange = (e) => {
    const { value } = e.target;
    setEditedService({ ...editedService, type: value });
  };

  const handleMapaChange = (e) => {
    const { value } = e.target;
    setEditedService({ ...editedService, mapa: value });
  };

  const handleSaveClick = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/service/service/${selectedService.id}`, editedService, {
        withCredentials: true
      });
      // Actualiza la lista de servicios después de la edición
      const updatedServices = userServices.map((service) => {
        if (service.id === selectedService.id) {
          return { ...service, ...editedService };
        }
        return service;
      });
      setUserServices(updatedServices);
      handleEditComplete();
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
      console.error(errorMessage);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/service/service/${serviceId}`, {
        withCredentials: true
      });
      if (response.status === 200) {
        alert('Servicio eliminado con éxito.');
        // Actualizar la lista de servicios después de eliminar
        const updatedServices = userServices.filter(service => service.id !== serviceId);
        setUserServices(updatedServices);
        setSelectedService(null);
      }
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data.error : 'Error desconocido';
      console.error(errorMessage);
      const errorStatus = error.response && error.response.status ? error.response.status : 500;
      alert(errorStatus);
    }
  };

  const handleUploadImages = async () => {
    try {
      const formData = new FormData();

      for (const file of selectedFiles) {
        formData.append('image', file);
      }

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/service/service/${selectedService.id}/upload`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        alert('Imágenes subidas con éxito.');
        // Asumiendo que response.data es la última ruta de imagen
        setServiceImages([response.data]); // Reemplaza el estado con solo la última imagen
        setSelectedFiles(null);
        fetchServiceImages(selectedService.id);
      }
    } catch (error) {
      console.error('Error al subir las imágenes:', error);
      alert('Error al subir las imágenes. Por favor, inténtalo de nuevo.');
    }
  };


  return (
    <div className="service-form rounded-4" style={{ marginTop: '-10px' }}>
      <h2 className="service-form__title">Mis Servicios</h2>
      <ul className="service-list">
        {userServices.map((service) => (
          <li className="service-list__item rounded-3" style={{ marginLeft: '-20px' }} key={service.id}>
            <h3 className="service-list__name">{service.name}</h3>
            <div className="service-list__actions">
              <button className="service-list__button-edit" onClick={() => handleEditClick(service)}>Editar</button>
              <button className="service-list__button-delete" onClick={() => handleDeleteService(service.id)}>Eliminar</button>
            </div>
          </li>
        ))}
      </ul>


      {selectedService && (
        <div className="service-details">
          <h2 className="service-details__title">Detalles del Servicio</h2>
          {isEditing ? (
            <div className="service-edit">
              <h3 className="service-edit__heading">Editar Servicio</h3>
              {/* Form groups with unique class names */}
              <div className="input-group" style={{ justifyContent: 'center' }}>
                <label style={{ marginRight: '5px', marginTop: '5px' }}>Tipo de Servicio:</label>
                <select
                  className='rounded-2 text-white'
                  name="type"
                  value={editedService.type || ''}
                  onChange={handleTypeChange}
                >
                  <option value="">Seleccione un tipo</option>
                  {serviceTypes.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="service-edit__group">
                <label className="service-edit__label">Nombre:</label>
                <input
                  type="text"
                  name="name"
                  className="service-edit__input"
                  value={editedService.name}
                  onChange={handleInputChange}
                />
              </div>

              <label style={{ marginRight: '5px' }}>Descripción:</label>
              <br />
              <textarea
                className='edit-description rounded-2 text-white'
                name="description"
                value={editedService.description}
                onChange={handleInputChange}
              ></textarea>

              <div className="input-group" style={{ justifyContent: 'center' }}>
                <label>WhatsApp:</label>
                <input
                  type="text"
                  name="whatsapp"
                  value={editedService.whatsapp}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-group" style={{ justifyContent: 'center' }}>
                <label>Facebook:</label>
                <input
                  type="text"
                  name="facebook"
                  value={editedService.facebook}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-group" style={{ justifyContent: 'center' }}>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={editedService.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-group" style={{ justifyContent: 'center' }}>
                <label>Dirección:</label>
                <input
                  type="text"
                  name="address"
                  value={editedService.address}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-group mt-4" style={{ justifyContent: 'center' }}>
                <label style={{ marginRight: '5px', marginTop: '5px' }}>Mapa (Link):</label>
                <input
                  className='rounded-2'
                  type="url" // Use "text" if you don't want to enforce URL validation
                  name="mapa"
                  value={editedService.mapa || ''}
                  onChange={handleMapaChange}
                />
              </div>
              <div className="service-edit__buttons">
                <button className="service-edit__button-save" onClick={handleSaveClick}>Guardar</button>
                <button className="service-edit__button-cancel" onClick={handleEditComplete}>Cancelar</button>
              </div>
            </div>
          ) : (
            <div className="service-view">
              <h3>{selectedService.name}</h3>
              <p>{selectedService.description}</p>
              <p>WhatsApp: {selectedService.whatsapp}</p>
              <p>Facebook: {selectedService.facebook}</p>
              <p>Email: {selectedService.email}</p>
              <p>Dirección: {selectedService.address}</p>
              <p>Tipo: {selectedService.type}</p>
              <p>Mapa: {selectedService.mapa}</p>
              <button className="service-view__button-edit" onClick={() => handleEditClick(selectedService)}>Editar</button>
            </div>
          )}

          <div className="service-images mt-4">
            <h2>Imágenes del Servicio</h2>
            {serviceImages.map((imagePath) => (
              <div className="service-card" key={imagePath} style={{ marginTop: '-10px' }}>
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}${typeof imagePath === 'string' ? imagePath.replace(/\\/g, '/').replace('/var/www', '') : ''}`}
                  className="card-img-top"
                  alt={`Servicio ${typeof imagePath === 'string' ? imagePath : 'Imagen'}`}
                />

              </div>
            ))}
          </div>

          <div className="service-upload" style={{ marginTop: '-10px' }}>
            <h3>Actualizar Imagen</h3>
            <input className='mt-2 rounded-3' style={{ backgroundColor: 'darkolivegreen' }} type="file" name="image" onChange={handleFileChange} />
            <button className="service-upload__button-upload mt-4" onClick={handleUploadImages}>Subir imagen </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditarServicio;
