import React from "react";
import "./Home.css";
import { BsInfoCircleFill } from "react-icons/bs"; // Importa los íconos bootstrap
import CarouselCuadrado from "../Carrusel/CarruselCuadrado";

const Novedades_Home = () => {

  const isSmallScreen = window.innerWidth <= 800;

  const containerStyle = {
    maxWidth: isSmallScreen ? "300px" : "450px",
    height: isSmallScreen ? "300px" : "450px",
  };

  const imagePaths1 = [
    "/img/carrusel/carrusel-novedades-home-1.png",
    "/img/carrusel/carrusel-novedades-home-2.png",
    "/img/carrusel/carrusel-novedades-home-5.png"
  ];

  const imagePaths2 = [
    "/img/carrusel/carrusel-novedades-home-3.png",
    "/img/carrusel/carrusel-novedades-home-4.png",
    "/img/carrusel/carrusel-novedades-home-6.png"
  ];

  return (
    <div>
      <h1 className="text-white mt-3">
        <b>ATRACTIVOS</b>
      </h1>

      <div className="container-atractivos-home col-12 col-md-8">
        <div className="row-2">
          <div className="card-atractivos-home rounded-3 m-3">
            <div className="card-body-atractivos-home rounded-3">
              <h3 className="card-title-atractivos-home text-white alignItems" style={{ textDecoration: 'underline' }}>
                <b>Monjes Blancos</b>
              </h3>
              <h4 style={{ textAlign: 'center', marginTop: '10px' }}>
                {isSmallScreen ? 'Mirador, escalada.' : 'Extrañas formaciones se produjeron por una erupción volcánica hace más de 7 millones de años, los materiales como rocas y cristales cayeron a la superficie y se fueron compactando hasta formar las rocas piroclásticas.'}
              </h4>
              {!isSmallScreen && (
                <p style={{ textAlign: 'center', color: 'white', fontFamily: 'Arial', fontSize: '18px' }}>Mirador, escalada.</p>
              )}
              <div className="btn-atractivos-home">
                <a href="/Atractivos" className="boton-informacion-atractivos-home text-white">
                  <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                  <b>Más Información</b>
                </a>
              </div>
            </div>
          </div>

          <div className="card-group">
            <div className="card d-flex align-items-center m-3 rounded-3" style={{ containerStyle }}>
              <CarouselCuadrado imagePaths={imagePaths1} />
            </div>

            <div className="card-mapa-novedades-home d-flex align-items-center m-3 rounded-3">
              <div className="container text-white">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1601.5433936967936!2d-70.55718184487121!3d-35.988341650732195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe7195651706f%3A0xfcc4f9a1fa05610e!2sMonjes%20Blancos!5e1!3m2!1ses-419!2scl!4v1703619605756!5m2!1ses-419!2scl"
                  style={{
                    height: isSmallScreen ? "300px" : "450px",
                    width: isSmallScreen ? "108%" : "460px", // Ancho específico para dispositivos no móviles
                    marginLeft: isSmallScreen ? "-12px" : "0px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-atractivos-home col-12 col-md-8">
        <div className="row-2">
          <div className="card-atractivos-home rounded-3">
            <div className="card-body-atractivos-home rounded-3">
              <h3 className="card-title-atractivos-home text-white alignItems" style={{ textDecoration: 'underline' }}>
                <b>Cascada Invertida</b>
              </h3>
              <h4 style={{ textAlign: 'center', marginTop: '10px' }}>
                {isSmallScreen ? 'Mirador, escalada.' : 'Descubre un asombroso lugar, donde el agua desafía la gravedad y crea un espectáculo mágico. Podrás experimentar el hipnótico y surreal paisaje lleno de vegetación y montañas admirables.'}
              </h4>
              {!isSmallScreen && (
                <p style={{ textAlign: 'center', color: 'white', fontFamily: 'Arial', fontSize: '18px' }}>Mirador, escalada.</p>
              )}
              <div className="btn-atractivos-home">
                <a href="/Atractivos" className="boton-informacion-atractivos-home text-white">
                  <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                  <b>Más Información</b>
                </a>
              </div>
            </div>
          </div>

          <div className="card-group">

            <div className="card-mapa-novedades-home d-flex align-items-center m-3 rounded-3">
              <div className="container text-white">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d801.1139544591928!2d-70.5739888465664!3d-35.954607642811915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe688049f32a7%3A0x2b384c1a72e96f1d!2sCascada%20Invertida!5e1!3m2!1ses-419!2scl!4v1703619708906!5m2!1ses-419!2scl"
                  style={{
                    height: isSmallScreen ? "300px" : "450px",
                    width: isSmallScreen ? "108%" : "460px", // Ancho específico para dispositivos no móviles
                    marginLeft: isSmallScreen ? "-12px" : "0px",
                  }}
                ></iframe>
              </div>
            </div>

            <div className="card d-flex align-items-center m-3 rounded-3" style={{ containerStyle }}>
              <CarouselCuadrado imagePaths={imagePaths2} />
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};
export default Novedades_Home;