import React from "react";
import "./Home.css";
import ImageCarousel from "../Carrusel/Carrusel";
import Novedades_Home from "./Novedades_Home";
import Esenciales_Home from "./Esenciales_Home";
import Contactos_Home from "./Contactos_Home";

const Home = () => {
  const imagePaths = [
    "/img/carrusel/carrusel-home-1.jpg",
    "/img/carrusel/carrusel-home-4.jpg",
    "/img/carrusel/carrusel-home-9.jpg",
    "/img/carrusel/carrusel-home-6.jpg",
    "/img/carrusel/carrusel-home-7.jpg",
    "/img/carrusel/carrusel-home-8.jpg",
  ];

  const containerStyle = {
    maxWidth: '98%', // Ajusta esto según sea necesario
    margin: '0 auto',
  };

  return (
    <div className="inicio-home">

      {/* CARRUSEL */}
      <div style={containerStyle}>
        <ImageCarousel imagePaths={imagePaths} />
      </div>

      {/* SECCIÓN NOVEDADES */}
      <Novedades_Home />

      {/* SECCIÓN ESENCIALES */}
      <Esenciales_Home />

      {/* SECCIÓN CONTACTO */}
      <Contactos_Home />

    </div>
  );
};
export default Home;
