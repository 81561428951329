import React from "react";
import Datasomos from "./Datasomos.json";
import { Button, Modal, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { BsFacebook, BsWhatsapp, BsEnvelope, BsInfoCircleFill } from "react-icons/bs";
import ModalGuias from "./Modals/ModalGuias";
import CarouselCuadrado from "../Carrusel/CarruselCuadrado";

const Quienessomos = () => {
  const isSmallScreen = window.innerWidth <= 800;
  const [showModal, setShowModal] = useState(false);
  const [showModalGuia, setShowModalGuia] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [guia, setGuia] = useState({});

  const handleClose = () => {
    setShowModal(false);
  };

  const handleCloseModalGuia = () => {
    setShowModalGuia(false);
  };

  const handleShow = (cardIndex) => {
    setSelectedCard(cardIndex);
    setShowModal(true);
  };

  const handleShowModal = (cardIndex, guia) => {
    setGuia(guia);
    setShowModalGuia(true);
  };

  const cardsData = [
    {
      title: "Cámara de Turismo",
      info: "Asociación de emprendedores turísticos de la Ruta Pehuenche (CH-115), Maule"
    },
  ];

  let whatsapp = "";
  let correo = "";

  whatsapp = (
    <h2 className="social-icon-quienes-somos" style={{marginLeft:'40px'}}>
      <BsWhatsapp style={{ marginTop: '6px', fontSize: '30px' }}/>
      <a href={"https://wa.link/xdte5o"}
        target="_blank" rel="noopener noreferrer"
        style={{
          fontSize: isSmallScreen ? "25px" : "20px",
        }}
      >Whatsapp</a>

    </h2>
  );
  correo = (
    <h2 className="social-icon-quienes-somos" style={{marginLeft:'40px'}}>
      <BsEnvelope style={{ marginTop: '6px', fontSize: '35px'}} />
      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=turismopasopehuenche@gmail.com&su=Consulta sobre la Cámara de Turismo" target="_blank"
        style={{
          fontSize: isSmallScreen ? "33px" : "20px"
        }}
      >Correo</a>
    </h2>
  );

  const imagePaths = [
    "/img/quienes-somos/campamento.jpg",
    "/img/quienes-somos/andes touring.jpg",
    "/img/quienes-somos/paso internacional.jpeg",
    "/img/quienes-somos/camara de turismo fundacion.jpg",
    "/img/quienes-somos/camara de turismo expedicion.png",
    "/img/quienes-somos/nieve-ua.jpg",
    "/img/quienes-somos/paso internacional laguna del maule.jpg",
    "/img/quienes-somos/laguna2.jpg",
    "/img/quienes-somos/pehuenche.jpg",
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <h1 className={`text-white m-3 ${isMobile ? 'text-mobile' : ''}`} style={{ textDecoration: 'underline' }}>
        <b>¿Quiénes Somos?</b>
      </h1>

      <div className="d-flex align-items-center justify-content-center">
        {cardsData.map((card, index) => (
          <Col key={index} md={8}>
            <div>
              <Row className={`text-center ${isMobile ? 'mx-0' : ''}`}>
                <Col xs={12} md={6} className={`${isMobile ? '' : 'd-flex flex-column justify-content-center align-items-center'}`}>
                  <div className="card-body-quienes-somos" style={{ textAlign: isMobile ? 'center' : 'center', padding: isMobile ? '0' : '0 20px' }}>
                    <h3 className="card-title text-white alignItems" style={{ marginTop: isMobile ? '10px' : '0px', fontSize: isMobile ? '24px' : '32px' }}>
                      <b>{card.title}</b>
                    </h3>
                    <h4 className={`mt-3 ${isMobile ? 'text-mobile' : ''}`} style={{ fontSize: isMobile ? '18px' : '24px' }}>{card.info}</h4>
                    <Button
                      className={`boton-informacion-atractivos-home text-white mt-3 ${isMobile ? 'button-mobile' : ''}`}
                      onClick={() => handleShow(index)}
                      style={{ fontSize: isMobile ? '14px' : '18px', padding: isMobile ? '10px 20px' : '10px 30px' }}
                    >
                      <BsInfoCircleFill style={{ marginTop: '-3px' }} />
                      <b style={{ margin: "5px" }}>Contáctanos</b>
                    </Button>
                  </div>
                </Col>
                <Col xs={12} md={4} className={`text-center mt-5 ${isMobile ? 'm-3 mx-0' : 'd-flex align-items-center justify-content-center'}`} style={{ marginLeft: '40px' }}>
                  <CarouselCuadrado imagePaths={imagePaths} />
                </Col>
              </Row>
            </div>
          </Col>
        ))}
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header style={{ backgroundColor: "#537692" }} closeButton>
          <Modal.Title className="text-white text-center">
            Contáctanos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#537692" }}>
          <div className="d-flex align-items-center">
            <img
              src="/img/quienes-somos/camara de turismo expedicion.png"
              alt=""
              className="rounded-3"
              style={{
                width: isSmallScreen ? "50%" : "50%",
                height: isSmallScreen ? "180px" : "200px",
              }}
            />
            <div className="columna ml-auto">
              <div className="social-media-quienes-somos">
                {whatsapp}
                {correo}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#537692" }}>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalGuias guia={guia} handleCloseModalGuia={handleCloseModalGuia} showModalGuia={showModalGuia} />

      <div className="container text-white">
        <div className="row mt-5 text-center" style={{ textDecoration: 'underline' }}>
          <br></br>
          <h2>
            <b>NUESTROS REPRESENTANTES</b>
          </h2>
          <div className="container d-flex justify-content-center mt-2">
            <div className="row">
              {Datasomos.map((QuienesSomos, index) => (
                <div key={QuienesSomos.id} className="col-md-3 mt-5">
                  <div className="card-guias text-white">
                    <img
                      src={QuienesSomos.imagen}
                      alt={QuienesSomos.nombre}
                      className="card-guias-img-top"
                      style={{
                        objectFit: "cover",
                        width: "200px",
                        height: "200px",
                        borderRadius: "50%",
                      }}
                    />
                    <h5 className="card-guias-title mt-3">
                      <a ref={QuienesSomos.url}>{QuienesSomos.nombre}</a>
                    </h5>
                    <p className="card-guias-text">
                      {QuienesSomos.descripcion}
                    </p>
                    <Button
                      className={`boton-informacion-atractivos-home text-white m-3 ${isMobile ? 'button-mobile' : ''}`}
                      onClick={() => handleShowModal(index, QuienesSomos)}
                    >
                      <BsInfoCircleFill style={{ marginTop: '-3px' }} />
                      <b style={{ margin: "5px" }}>Más Información</b>
                    </Button>

                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quienessomos;