import React, { useState, useEffect } from 'react';

const containerStyle = {
  width: '100%',
  height: '810px'
};

const Map = () => {
  const [mapWidth, setMapWidth] = useState(window.innerWidth <= 800 ? '100%' : '121%');

useEffect(() => {
  const handleResize = () => {
    setMapWidth(window.innerWidth <= 800 ? '100%' : '121%');
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
  return (
    <div className="inicio">
      {/* SECCIÓN ESENCIALES */}
      <div className="container mt-5 text-white">
        {/* MAPA SECCIÓN NOVEDADES */}
        <div className="container text-white">
          <div
            id="Mapa_Inicio"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "900px",
              width: "100%",
            }}
          >
            <iframe
              /* Link Mapa Esenciales */
              style={{ borderRadius: '10px', width: mapWidth, height: '530px', marginTop: '-40px' }}
              src="https://www.google.com/maps/d/u/3/embed?mid=1ViNgSj2BRJAJekI2WMTCR-05ezE8FyQ&ehbc=2E312F"
              title="Mapa_Esenciales"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
