import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CrearServicioForm from './CrearServicio';
import EditarServicio from './EditarServicio';
import InfoPersonal from './InfoPersonal';
import Perfil from './Perfil';
import EditarUsuarios from './EditarUsuarios'; // Import the EditarUsuarios component
import './Style/Admin.css';
import 'react-tabs/style/react-tabs.css';

const Admin = () => {
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/user/role`, {
                    withCredentials: true
                });
                setUserRole(response.data.role);
            } catch (error) {
                console.error('Error al obtener el rol del usuario:', error);
            }
        };

        fetchUserRole();
    }, []);

    return (
        <div className="admin">
            <Tabs className="tab" style={{ marginTop: '20px' }}>
                <TabList>
                    <Tab>Perfil</Tab>
                    <Tab>Editar Datos Personales</Tab>
                    {(userRole === 'admin' || userRole === 'creador') && <Tab>Crear Servicio</Tab>}
                    {(userRole === 'admin' || userRole === 'creador') && <Tab>Mis Servicios</Tab>}
                    {userRole === 'admin' && <Tab>Editar Usuarios</Tab>}
                </TabList>

                <TabPanel>
                    <div className="services-section mt-3 text-white">
                        <h2>Perfil</h2>
                        <Perfil />
                    </div>
                </TabPanel>
                <TabPanel>
                    <InfoPersonal />
                </TabPanel>

                {(userRole === 'admin' || userRole === 'creador') && (
                    <TabPanel>
                        <div className="services-section mt-3 text-white">
                            <h2>Crear Servicio</h2>
                            <CrearServicioForm
                                onServiceAdded={() => {
                                    // Handle service added
                                }}
                            />
                        </div>
                    </TabPanel>
                )}

                {(userRole === 'admin' || userRole === 'creador') && (
                    <TabPanel>
                        <div className="services-section">
                            <EditarServicio />
                        </div>
                    </TabPanel>
                )}

                {userRole === 'admin' && (
                    <TabPanel>
                        <EditarUsuarios /> 
                    </TabPanel>
                )}
            </Tabs>
        </div>
    );
};

export default Admin;