import React, { useState, useEffect } from 'react';
import "./Home.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Esenciales_Home = () => {
    const [mostrarBotones, setMostrarBotones] = useState(false);
    const [tamanioReducido, setTamanioReducido] = useState(false);

    const toggleMostrarBotones = () => {
        setMostrarBotones(!mostrarBotones);
    };

    useEffect(() => {
        const actualizarTamanioVentana = () => {
            setTamanioReducido(window.innerWidth <= 768); // Puedes ajustar el valor según tus necesidades
        };

        // Agregar el evento de cambio de tamaño de ventana
        window.addEventListener('resize', actualizarTamanioVentana);

        // Llamar a la función una vez para configurar el estado inicial
        actualizarTamanioVentana();

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener('resize', actualizarTamanioVentana);
        };
    }, []);

    const botonesContainerStyle = {
        display: tamanioReducido ? (mostrarBotones ? 'flex' : 'none') : 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
    };

    const botonInformacionStyle = {
        margin: '10px',
        flex: '1 1 200px'
    };

    return (
        <div className="container-esenciales-home mt-5 text-white">
            <div id="Esenciales">
                <h1>
                    <b>ESENCIALES</b>
                </h1>

                <div className="container-esenciales-home text-white">
                    {tamanioReducido && (
                        <button className='boton-movil-esenciales-home rounded-5' style={{ backgroundColor: '#145c96', fontWeight: 'bold' }} onClick={toggleMostrarBotones}>
                            {mostrarBotones ? 'Ocultar Servicios Esenciales' : 'Mostrar Servicios Esenciales'}
                        </button>
                    )}
                    <div className="botones-esenciales-home" style={botonesContainerStyle}>
                        <a href="https://www.google.com/maps/d/u/3/edit?mid=1QnlDN3jLwyRlCtSCt8ohzgo-PZ0i0F8&usp=sharing" className="boton-informacion-esenciales-home text-white" target="_blank" rel="noopener noreferrer" style={botonInformacionStyle}>
                            <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                            <b>Bencineras</b>
                        </a>
                        <a href="https://www.google.com/maps/d/u/3/edit?mid=18ndIH44BSiecSVyeL8uFxQWW8rCab8k&usp=sharing" className="boton-informacion-esenciales-home text-white" target="_blank" rel="noopener noreferrer" style={botonInformacionStyle}>
                            <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                            <b>Alojamientos</b>
                        </a>
                        <a href="https://www.google.com/maps/d/u/3/edit?mid=1HVnkOruVoTSjjko5QMgITuKvOywHne8&usp=sharing" className="boton-informacion-esenciales-home text-white" target="_blank" rel="noopener noreferrer" style={botonInformacionStyle}>
                            <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                            <b>Alimentación</b>
                        </a>
                        <a href="https://www.google.com/maps/d/u/3/edit?mid=1P9_gwVedlflPzwiPwfB-67AoEiyzs1k&usp=sharing" className="boton-informacion-esenciales-home text-white" target="_blank" rel="noopener noreferrer" style={botonInformacionStyle}>
                            <BsInfoCircleFill style={{ marginTop: '-3px' }} /> {<i className="bi bi-info-circle"></i>}
                            <b>Supermercados</b>
                        </a>
                    </div>

                    <iframe
                        className="mt-4"
                        /* Link Mapa Esenciales */
                        src="https://www.google.com/maps/d/u/3/embed?mid=1P9jdkRWRxxV00bb52eC8Rq2t37O6nDY&ehbc=2E312F"
                        border-radius='10px'
                        width='100%'
                        height='400px'
                        style={{ marginTop: '10px' }}
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Esenciales_Home;
