import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaStar, FaTrashAlt } from 'react-icons/fa';
import './Style/Comments.css';

const Comments = ({ serviceId }) => {
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkOwner = async () => {
            try {
                const ownerResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/service/${serviceId}/isOwner`, { withCredentials: true });
                setIsOwner(ownerResponse.data.isOwner);
            } catch (err) {
                console.error(err);
            }
        };
        checkOwner();
    }, [serviceId]);

    useEffect(() => {
        const fetchComments = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/services/${serviceId}/comments`);
                const commentsWithImageUrl = response.data.map(comment => {
                    const imagePath = comment.profile_image_path;
                    const imageUrl = imagePath.startsWith('http') ? imagePath : `${process.env.REACT_APP_SERVER_URL}/${imagePath.replace(/\\/g, '/')}`;
                    return { ...comment, profile_image_path: imageUrl };
                });
                setComments(commentsWithImageUrl);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchComments();
    }, [serviceId]);
    if (isLoading) return <div>Cargando comentarios...</div>;

    const deleteComment = async (commentId) => {
        if (window.confirm('¿Estás seguro de que quieres eliminar este comentario?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/service/comment/${commentId}`,  { withCredentials: true });
                setComments(comments.filter(comment => comment.id !== commentId));
            } catch (err) {
                setError(err.message || 'Error al eliminar el comentario');
            }
        }
    };

    return (
        <div className="comments-container">
            {comments.length > 0 ? (
                comments.map((comment, index) => (
                    <div key={index} className="comment-card">
                        <div className="comment-header">
                            <img src={comment.profile_image_path || 'default-profile.png'} alt="Perfil" className="user-image" />
                            <div>
                                <div className="user-name">{comment.name_full || 'Usuario Anónimo'}</div>
                                <div className="comment-rating">
                                    {[...Array(5)].map((_, i) => (
                                        <FaStar key={i} className={i < comment.rating ? 'star-filled' : 'star-empty'} />
                                    ))}
                                </div>
                                <div className="comment-date">{new Date(comment.created_at).toLocaleDateString()}</div>
                            </div>
                        </div>
                        <div className="comment-body">
                            {comment.comment.split(' ').slice(0, 20).join(' ')}...
                        </div>
                        {isOwner && (
                        <button onClick={() => deleteComment(comment.id)} className="delete-comment-button">
                            <FaTrashAlt /> Eliminar
                        </button>
                    )}
                    </div>
                ))
            ) : (
                <p>No hay comentarios aún.</p>
            )}
        </div>
    );
};

export default Comments;