import React from "react";
import "../Home/Home.css";
import ImageCarousel from "../Carrusel/Carrusel";
import CarouselCuadrado from "../Carrusel/CarruselCuadrado";

const Novedades = () => {
  const imagePaths1 = [
    "/img/novedades/imagen2.jpg",
    "/img/novedades/imagen1.png",
    "/img/novedades/imagen3.png"
  ];

  const imagePaths2 = [
    "/img/novedades/imagen5.png",
    "/img/novedades/imagen4.png",
  ];

  const imagePaths3 = [
    "/img/novedades/monjes.png",
    "/img/novedades/paso internacional laguna del maule.png",
    "/img/novedades/rocosas.png"
  ];

  const isSmallScreen = window.innerWidth <= 900;

  const containerStyle = {
    maxWidth: isSmallScreen ? "300px" : "450px",
    height: isSmallScreen ? "300px" : "450px",
  };


  return (
    <div className="Novedades">
      {/* Carrusel 1 */}
      <ImageCarousel imagePaths={imagePaths3} />

      {/* SECCIÓN NOVEDADES */}
      <h1 className="text-white mt-3">
        <b>ATRACTIVOS</b>
      </h1>

      <div className="container-atractivos-home col-12 col-md-8">
        <div className="row-2">
          <div className="card-atractivos-home rounded-3 m-3">
            <div className="card-body-atractivos-home rounded-3">
              <h3 className="card-title-atractivos-home text-white alignItems" style={{ textDecoration: 'underline' }}>
                <b>Monjes Blancos</b>
              </h3>
            </div>
            <div className="card-atractivos m-3">
              <h4 className="text-center">
                <p></p>
                <p>
                  ¿Como llegar?
                </p>
                <p>
                  Los monjes blancos se encuentran en la región del Maule por la Ruta 115 camino al Paso Internacional Pehuenche, a 6
                  minutos de la Cascada Invertida y a 4 minutos de la Aduana.
                </p>
                <p>
                  ¿Valor de la entrada y horarios?
                </p>
                <p>
                  Entrada liberada, abierto las 24 horas.
                </p>
              </h4>
            </div>
          </div>
          <div className="card-group">
            <div className="card d-flex align-items-center m-3 rounded-3" style={{ containerStyle }}>
              <CarouselCuadrado imagePaths={imagePaths1} />
            </div>

            <div className="card-mapa-novedades-home d-flex align-items-center m-3 rounded-3">
              <div className="container text-white">
                <iframe
                  src="https://www.google.com/maps/d/u/3/embed?mid=1qXBOSmctMjSMObpizhCkwntBmCksYkA&ehbc=2E312F"
                  style={{
                    height: isSmallScreen ? "300px" : "450px",
                    width: isSmallScreen ? "108%" : "460px", // Ancho específico para dispositivos no móviles
                    marginLeft: isSmallScreen ? "-12px" : "0px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-atractivos-home col-12 col-md-8">
        <div className="row-2">
          <div className="card-atractivos-home rounded-3 m-3">
            <div className="card-body-atractivos-home rounded-3">
              <h3 className="card-title-atractivos-home text-white alignItems" style={{ textDecoration: 'underline' }}>
                <b>Cascada Invertida</b>
              </h3>
            </div>
            <div className="card-novedades m-3">
              <h4 className="text-center">
                <p></p>
                <p>
                  ¿Como llegar?
                </p>
                <p>
                  Llegar hasta la ciudad de San Clemente y luego avanzar por la Ruta 115 durante 1 h 36 min (113 km).
                  Solo se puede llegar en auto propio, no hay transporte público.
                </p>
                <p>
                  ¿Valor de la entrada y horarios?
                </p>
                <p>
                  Entrada gratis. Abierto las 24 horas.
                </p>
              </h4>
            </div>
          </div>
          <div className="card-group">
            <div className="card d-flex align-items-center m-3 rounded-3" style={{ containerStyle }}>
              <CarouselCuadrado imagePaths={imagePaths2} />
            </div>

            <div className="card-mapa-novedades-home d-flex align-items-center m-3 rounded-3">
              <div className="container text-white">
                <iframe
                  src="https://www.google.com/maps/d/u/3/embed?mid=1MZRtH64FkqXfjXbFZXB_-FJNdw_C3N0&ehbc=2E312F"
                  style={{
                    height: isSmallScreen ? "300px" : "450px",
                    width: isSmallScreen ? "108%" : "460px", // Ancho específico para dispositivos no móviles
                    marginLeft: isSmallScreen ? "-12px" : "0px",
                  }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default Novedades;