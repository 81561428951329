import React, { useState, useEffect } from 'react';
import axios from 'axios';

function InfoPersonal() {
    const [userData, setUserData] = useState({
        name_full: '',
        email: '',
        password: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/me`, {
                    withCredentials: true
                });
                setUserData(response.data);
            } catch (error) {
                console.error('Error al obtener los datos del usuario:', error);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/api/auth/update`, userData, {
                withCredentials: true
            });

            if (response.data?.message === 'Perfil actualizado con éxito') {
                alert('Perfil actualizado con éxito');
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            alert('Error al actualizar el perfil');
        }
    };

    return (
        <>
            <div className="services-section mt-3 text-white">
                <h2>Editar Información Personal</h2>
            </div>
            <form onSubmit={handleSubmit} className="user-form" style={{ marginTop: '20px' }}>
                <div>
                    <label className='label text-white' htmlFor="name_full">Nombre completo</label>
                    <input type="text" id="name_full" name="name_full" value={userData.name_full} onChange={handleChange} />
                </div>
                <div>
                    <label className='label text-white mt-2' htmlFor="email">Correo</label>
                    <input type="email" id="email" name="email" value={userData.email} onChange={handleChange} />
                </div>
                <div>
                    <label className='label text-white mt-2' htmlFor="password">Contraseña</label>
                    <input type="password" id="password" name="password" value={userData.password} onChange={handleChange} />
                </div>
                <button className='label text-center mt-3' type="submit">Actualizar</button>
            </form>
        </>
    );
}

export default InfoPersonal;
