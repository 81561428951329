import React, { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Button, Navbar } from 'react-bootstrap';
import { BsFire, BsMoonStars, BsNut, BsHouse, BsPeople, BsMap, BsBell, BsGeo, BsShop, BsBuildings, BsCupStraw, BsCart4, BsImageAlt, BsCursor } from 'react-icons/bs'; // Importa el icono que deseas utilizar
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import VistaMovilHeader from './VistaMovilHeader';

/* HEADER INICIO */
const Header = () => {

  return (

    <header className="text-white" style={{ fontFamily: 'Arial', alignItems: 'center' }}>
      <VistaMovilHeader />

      {/* BARRA DE NAVEGACIÓN HEADER - INICIO */}
      <Navbar className="navbar navbar-expand-lg mt-3 justify-content-center" expand="lg" id="Barra_de_navegacion">
        <Navbar.Toggle className='justify-content-center' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='justify-content-center' id="basic-navbar-nav">
          <ul className="navbar-nav ml-auto">
            <Button as={NavLink} to="/home" className="nav-link text-white">
              HOME
              <BsHouse style={{ marginTop: '-5px', marginLeft: '3px' }} /><i className="bi bi-house"></i>
            </Button>

            {/* MENÚ DESPLEGABLE EN LA BARRA DE NAVEGACIÓN */}
            <Dropdown>
              <Dropdown.Toggle variant="transparent" className="nav-link" id="dropdown-basic text-white" style={{fontSize: '17px', marginTop: '0px' }}>
                SERVICIOS
                <BsNut style={{ marginTop: '-5px', marginLeft: '3px', fontSize: '20px' }} /><i className="bi bi-house"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to="/servicios/Artesanias" className="nav-link text-white" >
                  <BsShop style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-shop"></i>
                  Artesanias
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Alojamientos" className="nav-link text-white" >
                  <BsBuildings style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-buildings"></i>
                  Alojamientos
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Almacenes" className="nav-link text-white" >
                <BsShop style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-shop"></i>
                  Almacenes
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Campings" className="nav-link text-white">
                  <BsFire style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-fire"></i>
                  Campings
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/AireLibre" className="nav-link text-white">
                  <BsImageAlt style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-Image-Alt"></i>
                  Actividades
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Restaurantes" className="nav-link text-white" >
                  <BsCupStraw style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-cup-straw"></i>
                  Restaurantes
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Supermercados" className="nav-link text-white" >
                  <BsCart4 style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-cart4"></i>
                  Supermercados
                </Dropdown.Item>
                <Dropdown.Item as={NavLink} to="/servicios/Otros" className="nav-link text-white" >
                  <BsCursor style={{ marginTop: '-5px', marginRight: '5px' }} /><i className="bi bi-cursor"></i>
                  Otros
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button as={NavLink} to="/turismo" className="nav-link text-white">
              TURISMO
              <BsMap style={{ marginTop: '-5px', marginLeft: '3px' }} /><i className="bi bi-map"></i>
            </Button>
            <Button as={NavLink} to="/atractivos" className="nav-link text-white">
              ATRACTIVOS
              <BsBell style={{ marginTop: '-5px', marginLeft: '3px' }} /><i className="bi bi-bell"></i>
            </Button>
            <Button as={NavLink} to="/Quienessomos" className="nav-link text-white">
              ¿QUIÉNES SOMOS?
              <BsPeople style={{ marginTop: '-5px', marginLeft: '3px' }} /><i className="bi bi-people"></i>
            </Button>
            <Button as={NavLink} to="/mapa" className="nav-link text-white">
              MAPA
              <BsGeo style={{ marginTop: '-5px', marginLeft: '3px' }} /><i className="bi bi-geo-alt"></i>
            </Button>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

const App = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default App;