import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams from react-router-dom
import { Button } from 'react-bootstrap';
import { BsInfoCircleFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const Servicios = () => {
  const [services, setServices] = useState([]);
  const { type } = useParams(); // Use useParams hook to get the type parameter

  useEffect(() => {
    const fetchServices = async () => {
      try {
        console.log('type', type); // Log the type to verify it's being received
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/service/services/${type}`);
        console.log('response.data', response.data);
        setServices(response.data);
      } catch (error) {
        console.error('Error al obtener los servicios:', error);
      }
    };

    if (type) {
      fetchServices();
    }
  }, [type]);

  return (
    <div className="container text-white">
      <div className="row mt-4">
        <h2 className="text-start">
          <b>{type.toUpperCase()}</b> {/* Use the type variable here */}
        </h2>
        {services.map((service) => (
          <div key={service.id} className="col-lg-4 col-md-6 mb-4">
            <div className="card d-flex mt-3">
              <div className="overflow">
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}/${service.image_path.replace('\\', '/')}`} // Replace backslashes with forward slashes for URL
                  alt={service.name}
                  className="card-img-top"
                  style={{ objectFit: 'cover', height: '200px' }}
                />
              </div>
              <div className="card-body">
                <h5 className="card-title text-white" style={{marginTop:'10px'}}>{service.name}</h5>
              </div>
              <Button
                as={NavLink}
                to={`/DetalleServicio/${service.id}`} // Make sure the routing is set up for this path
                className="boton-informacion-servicio text-white"
              >
                <BsInfoCircleFill />
                <b>Más Información</b>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Servicios;
