// Contactos_Home.js

import React from "react";
import "./Home.css";
import { useParams } from "react-router-dom";
import { BsWhatsapp, BsEnvelopeAt } from "react-icons/bs";

const Contactos_Home = () => {
  let id = useParams();
  let mail = (
    <h3 className="social-icon-home">
      <BsEnvelopeAt style={{ marginTop: '-5px', marginLeft: '3px', fontSize: '25px' }} />
      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=turismopasopehuenche@gmail.com&su=Consulta sobre Paso Pehuenche" target="_blank">Click aquí para enviar correo</a>
    </h3>
  );

  let contac = (
    <h3 className="social-icon-home">
      <BsWhatsapp style={{ marginTop: '-5px', marginLeft: '3px', fontSize: '20px' }} />
      <a href="https://wa.link/zwixsj" target="_blank">Contacto</a>
    </h3>
  );

  return (
    <div>
      <div id="Contacto_Inicio-Home" className="contacto-inicio-home text-white">
        <h1 className="text-white mt-5">
          <b>Comunícate con Nosotros</b>
        </h1>
        <hr style={{ backgroundColor: "white" }} />
        <h2 className="text-white">
          <div className="social-media-home text-center">
            {mail}
          </div>
          <div className="social-media-home text-center">
            {contac}
          </div>
        </h2>
      </div>
      <div className="social-buttons-home text-center" style={{marginLeft:'-10px'}}>
        <a href="https://www.instagram.com/camaradeturismo_pasopehuenche?" target="_blank" rel="noopener noreferrer" className="btn btn-link m-2 p-0">
          <img className="social-button-ig-home rounded-4" src="/img/redes sociales/instagram.png" alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100082811558847" target="_blank" rel="noopener noreferrer" className="btn btn-link m-2 p-0">
          <img className="social-button-face-home rounded-3" src="/img/redes sociales/facebook.png" alt="Facebook" />
        </a>
      </div>
    </div>
  );
};

export default Contactos_Home;
