import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../Carrusel/Carrusel.css';

/* CARRUSEL DE IMÁGENES */
const CarouselCuadrado = ({ imagePaths }) => {
  const isMobile = window.innerWidth <= 700; // Definir el ancho que consideras como "móvil"

  return (
    <Carousel interval={3500}>
      {imagePaths.map((imagePath, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100 rounded-3"
            src={imagePath}
            alt={`Slide ${index}`}
            style={{
              width: isMobile ? '100px' : '100%', // Ajustar el ancho
              height: isMobile ? '280px' : '100%', // Ajustar el alto
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselCuadrado;
