import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { BsPerson, BsBoxArrowRight, BsArrowRightCircleFill, BsDoorOpenFill } from 'react-icons/bs';
import './Header.css';
import axios from 'axios';

const VistaMovilHeader = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Ajustes para el tamaño de texto e imagen
  const anchoVentana = window.innerWidth;
  let tamañoTexto = '28px';
  let anchoMaximoImagen = '130px';
  let margenIzquierdaTexto = '40px';
  let margenArribaTexto = '26px';

  if (anchoVentana < 800) {
    tamañoTexto = '20px';
    anchoMaximoImagen = '100px';
    margenIzquierdaTexto = '10px';
    margenArribaTexto = '20px';
  }

  // Ajustes para el tamaño y posición de los botones
  let tamañoTextoBotones = '17px';
  let margenArribaBotones = '-50px';
  let margenBotones = '5px';
  let tamañoBotones = "150px";

  if (anchoVentana < 800) {
    tamañoTextoBotones = '12px';
    margenBotones = '5px';
    margenArribaBotones = '-70px';
    tamañoBotones = '110px';
  }

  const buttonStyle = {
    fontSize: tamañoTextoBotones,
    marginTop: margenArribaBotones,
    margin: margenBotones,
    width: tamañoBotones,
  };


  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/auth/check-login-status`, { withCredentials: true });
        setIsLoggedIn(response.data.isLoggedIn);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    checkLoginStatus();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/logout`, {}, { withCredentials: true });
      if (response.data.success) {
        setIsLoggedIn(false);
        window.location.href = '/home';
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex justify-content-start">
        <div className="navbar-brand">
          {/* LOGO INICIO - HEADER */}
          <div className="logo" style={{ maxWidth: '100px' }}>
            <img
              src="/img/logos/turismo-paso-pehuenche.png"
              className='rounded-3'
              alt=""
              style={{ width: anchoMaximoImagen }}
            />
          </div>

          <h1 className='titulo-logo' style={{ fontFamily: 'Segoe Script', fontSize: tamañoTexto, marginLeft: margenIzquierdaTexto, width: tamañoBotones, marginTop: margenArribaTexto }}>
            <b>Paso <br />Pehuenche</b>
          </h1>
        </div>
      </div>

      {/* Botones movidos al lado derecho */}
      <div className="d-flex justify-content-end">
        <div>
          {isLoggedIn ? (
            // Botones para usuarios autenticados
            <div className='botones1-header'>
              <Button as={NavLink} to="/perfil" className="boton-perfil text-white" style={buttonStyle}>
                <BsPerson style={{ marginTop: '-4px' }} />
                <b>Perfil</b>
              </Button>
              <Button as={NavLink} to="/Cerrar-sesion" className="boton-cerrar-sesion text-white" style={buttonStyle} onClick={handleLogout}>
                <BsBoxArrowRight style={{ marginTop: '-3px' }} />
                <b>Cerrar sesión</b>
              </Button>
            </div>
          ) : (
            // Botones para usuarios no autenticados
            <div className='botones2-header'>
              <Button as={NavLink} to="/registro" className="boton-registrarse text-white" style={buttonStyle}>
                <BsArrowRightCircleFill style={{ marginTop: '-3px', margin: '2px' }} />
                <b>Registrarse</b>
              </Button>
              <Button as={NavLink} to="/login" className="boton-login ml-2 text-white" style={buttonStyle}>
                <BsDoorOpenFill style={{ marginTop: '-5px' }} />
                <b>Login</b>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VistaMovilHeader;
