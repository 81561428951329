import React from 'react';
import './Footer.css';

/* FOOTER INICIO */
const Footer = () => {
  return (
    <footer className="footer text-white py-3 mt-5 text-center">
      <div className="image-container mt-5" style={{ display: 'flex', alignItems: 'flex-start' }}>
        
        {/* LOGOS INSTITUCIONALES */}
        <img src={"/img/logos/Fundación.jpg"} className='img1 mt-2 rounded-2' alt="Logo 2" style={{ width: '120px', marginLeft:'30px'}} />
        <img src={"/img/logos/UA.jpg"} className='img2 m-2 rounded-2' alt="Logo 1" style={{ width: '56px' }} />
        <img src={"/img/logos/camara-logo.jpg"} className='img3 rounded-2' alt="Logo 3" style={{ width: '56px', margin:'-1px', marginTop:'8px'}} />

        <p style={{marginLeft:'5px', marginTop:'15px'}}>&copy; {new Date().getFullYear()} Ruta Pehuenche</p>
      </div>
    </footer>

  );
};

export default Footer;