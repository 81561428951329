import { Modal, Button } from "react-bootstrap";

const ModalGuias = ({guia, handleCloseModalGuia, showModalGuia}) => {
    const {id, nombre, descripcion, imagen, whatsappLink, correoLink} = guia;
    return (
        <Modal show={showModalGuia} onHide={handleCloseModalGuia}>
            <Modal.Header style={{ backgroundColor: "#537692" }} closeButton>
                <Modal.Title className="text-white">
                    Información del Representante
                </Modal.Title>
            </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#537692" }}>
          <div className="d-flex align-items-center">
            <img
              src={imagen}
              alt=""
              className="rounded-3"
              style={{ width: "50%" }}
            />
            <div className="card columna ml-auto">
                <a className="text-center text-white text-lg font-weight-bold" style={{textDecoration:'underline'}}>{nombre}</a>
                <a className="text-center text-white text-lg font-weight-bold" style={{textDecoration:'underline'}}>{descripcion}</a>
                <a className="text-center text-white text-lg font-weight-bold">{whatsappLink}</a>
                <a className="text-center text-white text-lg font-weight-bold">{correoLink}</a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#537692" }}>
          <Button variant="secondary" onClick={handleCloseModalGuia}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ModalGuias;