import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import './LoginForm.css';

function LoginForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    showPassword: false
  });

  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((e) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: e.target.value
    }));
  }, []);

  const handleShowPassword = useCallback(() => {
    setFormData(prevFormData => ({ ...prevFormData, showPassword: !prevFormData.showPassword }));
  }, []);

  const handleError = useCallback((error) => {
    setIsLoading(false);
    if (error.response && error.response.data && error.response.data.message) {
      setMessage(error.response.data.message);
      setMessageType('error');
      console.error('Error during login:', error.response.data.message);
    } else {
      console.error('Unexpected error:', error);
    }
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/auth/login`, formData, { withCredentials: true });

      if (response.data && response.data.message) {
        setMessage(response.data.message);
        setMessageType('success');
        window.location.href = '/home';
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      handleError(error);
    }
  }, [formData, handleError]);

  return (
    <form onSubmit={handleSubmit} className="login-form mt-2">
      {message && <div className={`message ${messageType}`}>{message}</div>}
      <div className="login-form__icon-container">
        <FontAwesomeIcon icon={faSignInAlt} className="login-form__icon" />
      </div>
      <div className="login-form__group">
        <label htmlFor="email" className="login-form__label">Correo</label>
        <input type="email" className="login-form__input" id="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div className="login-form__group">
        <label htmlFor="password" className="login-form__label">Contraseña</label>
        <input
          type={formData.showPassword ? "text" : "password"}
          className="login-form__input"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <button
          style={{ marginTop: '10px' }}
          type="button"
          className="show-password-button square-button center-horizontally"
          onClick={handleShowPassword}
        >
          <img
            src={formData.showPassword ? "img/logos/ojo1.png" : "img/logos/ojo2.png"}
            alt="Imagen Si"
            className="icon-image text-white" 
          />
        </button>
      </div>
      <button type="submit" className="login-form__button" disabled={isLoading}>
        {isLoading ? 'Cargando...' : 'Ingresar'}
      </button>
    </form>
  );
}

export default LoginForm;