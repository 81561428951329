import React, { useState } from "react";
import "./turismo.css";
import { Button, Container, Modal } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";
import ImageCarousel from "../Carrusel/Carrusel";

const Turismo = () => {

    const isSmallScreen = window.innerWidth <= 800;

    const cardData = [
        { imgSrc: '/img/turismo/card-turismo-1.jpg', title: 'Cascada Invertida', description: 'descripción', map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2287.696874559649!2d-70.57564644816281!3d-35.95455990400438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe688049f32a7%3A0x2b384c1a72e96f1d!2sCascada%20Invertida!5e1!3m2!1ses-419!2scl!4v1704865684999!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-2.jpg', title: 'Cascada Arcoiris' , map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2287.6072149125266!2d-70.57198899022583!3d-35.957655672384554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe7eadd975961%3A0x4d678d7cf7dcdf27!2sCascada%20Arcoiris%2C%20ruta%20Internacional%20Pehuenche.!5e1!3m2!1ses-419!2scl!4v1704865751801!5m2!1ses-419!2scl'},
        { imgSrc: '/img/turismo/card-turismo-3.jpg', title: 'Valle de los Cóndores', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2287.3077139033444!2d-70.56184619022517!3d-35.96799517238138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe6e679da5ceb%3A0x54f2d4f8699b7538!2sValle%20de%20los%20C%C3%B3ndores!5e1!3m2!1ses-419!2scl!4v1704865901060!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-4.jpg', title: 'Monjes Blancos', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d73115.29310633779!2d-70.58436874272488!3d-36.05264428782737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe7195651706f%3A0xfcc4f9a1fa05610e!2sMonjes%20Blancos!5e1!3m2!1ses-419!2scl!4v1704866000347!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-5.jpg', title: 'Laguna del Maule', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d73115.29310633779!2d-70.58436874272483!3d-36.05264428782737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fdef6b19eb8b3%3A0xc5ebfeae766776d0!2sLaguna%20del%20Maule!5e1!3m2!1ses-419!2scl!4v1704866029231!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-6.jpg', title: 'Termas el Medano', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2291.495790061937!2d-70.76467219023458!3d-35.823178272427235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966ff39e7994fab3%3A0x34e9a5cd2c8b6c30!2sTermas%20El%20M%C3%A9dano%20Caba%C3%B1a!5e1!3m2!1ses-419!2scl!4v1704866061129!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-7.jpg', title: 'Muela del Diablo', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2288.083690670225!2d-70.59562999022695!3d-35.94120127238973!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe50e194b9c65%3A0x865d3dabae3c226a!2sMuela%20del%20Diablo%2C%20san%20Clemente!5e1!3m2!1ses-419!2scl!4v1704866115095!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-8.jpg', title: 'Baños termales El Campanario', map:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12821.150400279863!2d-70.58665733427542!3d-35.934098483246096!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966fe5693acd5cc7%3A0xb1998b168a6a4c2f!2sBa%C3%B1os%20termales%20Campanario!5e1!3m2!1ses-419!2scl!4v1704866258282!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-11.jpg', title: 'Lago Colbún', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12863.202747269122!2d-71.27432119805442!3d-35.67399905960034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96658374b223a717%3A0x8149adfd5158a4e2!2sLago%20Colb%C3%BAn!5e1!3m2!1ses-419!2scl!4v1704866291843!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo-12.jpg', title: 'Roca de Armerillo', map:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51446.18084841404!2d-71.16706134124478!3d-35.68428223179149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9665795f0109f053%3A0x5c973782498b564d!2sEstero%20Armerillo!5e1!3m2!1ses-419!2scl!4v1704866416210!5m2!1ses-419!2scl' },
        { imgSrc: '/img/turismo/card-turismo13.webp', title: 'Pozones de Armerillo', map:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2468.611865707444!2d-71.0718002319336!3d-35.680835723877!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x961387183a47de3f%3A0x9c136fdc7e3b638!2sTermas%20Los%20Pozones!5e1!3m2!1ses-419!2scl!4v1718924065564!5m2!1ses-419!2scl'}
    ];

    const imagePaths = [
        "/img/turismo/turismo13.png",
        "/img/turismo/turismo1.png",
        "/img/turismo/turismo2.png",
        "/img/turismo/turismo3.png",
        "/img/turismo/turismo6.png",
        "/img/turismo/turismo8.png",
        "/img/turismo/turismo9.png",
    ]

    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleShow = (cardIndex) => {
        setSelectedCard(cardIndex);
        setShowModal(true);
    };

    return (
        <div>
            {/* Carrusel */}
            <ImageCarousel imagePaths={imagePaths} />

            <h1 style={{ fontFamily: "Arial", color: "#FFF", margin: "20px" }}><b>TURISMO</b></h1>

            <div className="container text-white" style={{textAlign:'center'}}>
                <div className="row">
                    {cardData.map((card, index) => (
                        <div key={index} className="col-lg-4 col-md-6 mb-4">
                            <div className="card-turismo">
                                <div className="overflow">
                                    <img
                                        src={card.imgSrc}
                                        className="img-turismo"
                                        style={{ objectFit: 'cover', height: '190px' }}
                                        alt={`Card ${index}`}
                                    />
                                </div>
                                <div className="title-turismo">
                                    <h5 className="title-turismo text-white text-center">
                                        {card.title}
                                    </h5>
                                </div>
                                <Button
                                    className="boton-info-turismo text-white"
                                    onClick={() => handleShow(index)}
                                >
                                    <BsInfoCircleFill style={{ marginTop: '-5px', marginLeft: '5px', marginRight: '4px' }} />
                                    <b>Más Información</b>
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header style={{ backgroundColor: '#537692' }} closeButton>
                        <Modal.Title className="text-white" style={{textDecoration:'underline'}}>{selectedCard !== null ? cardData[selectedCard].title : ""}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#537692' }} >
                        {/* 
                        <div className="container">
                            <div className="card-turismo text-white">Información: {selectedCard !== null ? cardData[selectedCard].description : ""}</div>
                        </div> 
                        */}
                        <iframe src={selectedCard !== null ? cardData[selectedCard].map : ""}
                        style={{
                            width: isSmallScreen ? "100%" : "465px",
                            height: isSmallScreen ? "300px" : "270px"
                        }}    
                        >
                        </iframe>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: '#537692' }} >
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default Turismo;